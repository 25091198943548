import React from 'react';

import { navigate } from 'gatsby';

export default class EnglishPage extends React.Component {
  componentDidMount() {
    navigate('/patient-resources');
  }

  render() {
    return <></>;
  }
}
